import React from 'react'
import './landing.css'

const Landing = () => {
  return (
    <>
      <div className='new'>
        <div className='landing'>
          <h1 className='rainbow-text'>WCE ART CIRCLE</h1>
        </div>
      </div>
    </>
  )
}

export default Landing